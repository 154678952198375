import 'core-js/es';

import {
  loadElementScrollPolyfill,
  loadResizeObserverPolyfill,
  loadObjectFromEntriesPolyfill,
  loadFindLastIndexPolyfill,
  loadWebStreamsPolyfill,
  loadFlatMapPolyfill,
} from './polyfills';

Promise.all([
  loadElementScrollPolyfill(),
  loadResizeObserverPolyfill(),
  loadObjectFromEntriesPolyfill(),
  loadFindLastIndexPolyfill(),
  loadFlatMapPolyfill(),
  loadWebStreamsPolyfill(),
]).then(() => {
  import('./app');
});
